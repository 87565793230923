<template>
    <div class="alt-hero-banner" :class="$mq" :style="{ backgroundImage: 'url(' + image + ')' }">
        <div class="filter">
            <div class="hero-title text-center">
                <h1 v-html="title" class="zerotoone-fast"></h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AltHeroBanner",
    props: {
        title: String,
        image: String
    }
}
</script>

<style lang="scss" scoped>
    .alt-hero-banner {
        background-size: cover;
        background-attachment: fixed;
        background-position: 50%;
        height: 60vh;

        .filter {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            padding-top: 72px;
            background-color: rgba(0, 0, 0, .5);
        }

        .hero-title {
            margin-top: -72px;
            max-width: 50%;

            h1 {
                font-size: 55px;
                text-transform: uppercase;
            }
        }

        &.mobile {
            .hero-title {
                max-width: 80%;
            }
        }
    }
</style>