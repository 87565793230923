<template>
    <form class="contact-form">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="yourName">Votre nom</label>
                <input v-model="$v.form.name.$model" type="text" class="form-control" id="yourName" />
                <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.name.required && submitStatus === 'ERROR'">
                    {{ $t('form.required') }}
                </p>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="yourEmail">Votre adresse mail</label>
                <input v-model.trim.lazy="$v.form.email.$model" type="email" class="form-control" id="yourEmail" />
                <p class="font-weight-light small text-danger text-left" v-if="!$v.form.email.required && submitStatus === 'ERROR'">
                    {{ $t('form.required') }}
                </p>
                <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.email.email">
                    Adresse e-mail invalide.
                </p>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="yourMessage">Votre message</label>
                <textarea v-model="$v.form.message.$model" class="form-control" id="yourMessage" rows="3"></textarea>
                <p class="font-weight-light small text-danger text-left ml-3" v-if="!$v.form.message.required && submitStatus === 'ERROR'">
                    {{ $t('form.required') }}
                </p>
            </div>
        </div>

        <button class="btn btn-primary mt-3" @click.prevent="send" :disabled="submitStatus === 'PENDING'">Envoyer</button>
    </form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: "ContactForm",
    data () {
        return {
            form: {
                name: null,
                email: null,
                message: null
            },
            submitStatus: null,
            snackbarOptions: {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            }
        }
    },
    validations: {
        form: {
            name: { required },
            email: { required, email },
            message: { required }
        },
    },
    methods: {
        send() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitStatus = 'ERROR'
            } else {
                this.submitStatus = 'PENDING'

                window.emailjs.send('smtp_olina_lab', 'template_1ud4rwn', this.form)
                    .then(() => {
                        this.$toast.success("Merci, votre message a bien été transmis !", this.snackbarOptions);
                        this.form = { name: null, email: null, message: null }
                        this.submitStatus = 'OK'        
                    })
                    .catch((e) => {
                        this.$toast.error("Oops, une erreur est survenue...", this.snackbarOptions);
                        this.form = { name: null, email: null, message: null }
                        this.submitStatus = 'ERROR'
                    });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .contact-form {
        .form-control {
            font-size: 18px;
            box-shadow: none;
            display: block;
            width: 100%;
            min-height: 60px;
            padding: 18px 25px;
            font-weight: 300;
            line-height: 24px;
            color: #29293a;
            background-color: #f2f3f9;
            background-image: none;
            border: 0px solid #f2f3f9;
            border-radius: 35px;
            -webkit-appearance: none;
        }

        label {
            padding-left: 21px;
            margin-bottom: 3px;
            font-size: 12px;
            font-weight: 300;
            letter-spacing: .02em;
            color: #838386;
            pointer-events: auto;
            cursor: pointer;
        }

        button, button:focus {
            color: $white;
            background-color: $primary;
            border-color: $primary;
            min-width: 230px;
            padding: 14px 38px;
            border-radius: 35px;
            transition: background 0.3s 0.01s, border-color 0.3s 0.01s;
            box-shadow: none;

            &:hover {
                background-color: $secondary;
                border-color: $secondary;
            }
        }

        button:disabled, button[disabled] {
            &:hover {
                background-color: $primary !important;
                border-color: $primary !important;
            }
        }
    }
</style>