<template>
    <div class="contact">
        <AltHeroBanner
            title="Contact"
            :image="require('../assets/img/bg-header.jpg')"
        />
        <div class="container my-5">
            <h2>Contactez-nous !</h2>
            <p :class="{ 'w-100': $mq !== 'desktop', 'w-75': $mq === 'desktop' }">
                N'hésitez pas à nous contacter pour toutes demandes concernant vos projets, nous vous reponderons dans les plus brefs délais.
            </p>
            <div class="row">
                <div class="contact-form col-12 col-md-8 col-lg-9">
                    <ContactForm />
                </div>
                <div class="contact-infos col-12 col-md-4 col-lg-3 mt-5 mt-md-0 pr-md-0">
                    <div class="contact-mail">
                        <h4>
                            Écrivez-nous
                        </h4>
                        <p>
                            <a href="mailto:hello@olinalab.fr" class="btn w-100">
                                <i class="fas fa-envelope mr-2"></i> hello@olinalab.fr
                            </a>
                        </p>
                    </div>
                    <div class="contact-phone">
                        <h4>
                            Appelez-nous
                        </h4>
                        <p>
                            <a href="tel:0681946137" class="btn w-100">
                                <i class="fas fa-mobile-alt mr-2"></i> +33 6 81 94 61 37
                            </a>
                        </p>
                    </div>
                    <div class="contact-social-links">
                        <h4>
                            Réseaux sociaux
                        </h4>
                        <div class="contact-social-links-icons">
                            <a :href="fbLink">
                                <span class="icon mdi mdi-facebook"></span>
                            </a>
                            <a :href="instaLink">
                                <span class="icon mdi mdi-instagram"></span>
                            </a>
                            <a :href="ldLink">
                                <span class="icon mdi mdi-linkedin"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Map />
    </div>
</template>

<script>
import AltHeroBanner from '@/components/Banner/AltHeroBanner.vue'
import ContactForm from '@/components/Form/ContactForm.vue'
import Map from '@/components/Contact/Map.vue'

export default {
    name: "Contact",
    components: {
        AltHeroBanner,
        ContactForm,
        Map
    },
    data() {
        return {
            instaLink: process.env.VUE_APP_INSTAGRAM_URL,
            fbLink: process.env.VUE_APP_FACEBOOK_URL,
            ldLink: process.env.VUE_APP_LINKEDIN_URL
        }
    }
}
</script>

<style lang="scss" scoped>
    .contact {
        .contact-infos {
            display: grid;
            grid-template-columns: 1fr;

            .btn {
                color: $white;
                border-color: $white;
                border-radius: 35px;
                transition: 250ms ease-in-out;

                &:focus {
                    box-shadow: 0;
                }

                &:hover {
                    color: $primary;
                    border-color: $primary;
                }
            }

            .contact-social-links {
                h4 {
                    margin: 0;
                }

                .contact-social-links-icons {
                    a {
                        &:not(:last-of-type) {
                           margin-right: 20px; 
                        }

                        &:hover {
                            .icon {
                                color: $primary;
                            }
                        }

                        .icon {
                            font-size: 35px;
                            transition: 450ms ease-in-out;
                        }
                    }
                }
            }
        }
    }
</style>