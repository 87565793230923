<template>
    <div id="map">
    </div>
</template>

<script>
    import L from 'leaflet';
    import 'leaflet/dist/leaflet.css';
    //import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png')
    });

    let customIcon = L.divIcon({
        html: "<span class=\"icon mdi mdi-map-marker-radius mdi-36px\"></span>",
        className: 'mapIcon',
        iconSize: [36, 36], // size of the icon
    });

    export default {
        name: "Map",
        data() {
            return {
                map: null,
                mapOptions: {
                    closePopupOnClick: false,
                    trackResize: false,
                    scrollWheelZoom: false
                },
                location: "L'AIGLE, Orne <br> Notre rayon d'action"
            }
        },
        mounted() {
            // setting zoom based on breakpoints
            let zoom = this.$mq === 'mobile' ? 7 : 8;
            
            this.map = L.map('map', this.mapOptions);
            this.map.setView([48.7649502, 0.6259682], zoom);
            L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
                attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 20,
                minZoom: this.$mq === 'mobile' ? 7 : 8
            }).addTo(this.map);
            this.setIcons();
        },
        methods: {
            setIcons() {
                let marker = L.marker([48.7649502, 0.6259682], {icon: customIcon}).bindPopup(this.location).addTo(this.map);
                let circle = L.circle([48.7649502, 0.6259682], {radius: 100000, color: '#009C9A'}).addTo(this.map);
            }
        },
    }
</script>

<style lang="scss">
    #map {
        height: 60vh;
        width: 100%;

        .mapIcon {
            position: relative;
            color: $primary;

            .icon {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }

        .leaflet-popup-content {
            text-align: center;
        }
    }
</style>